import {createSelector} from '@reduxjs/toolkit'
import {createApi, retry} from '@reduxjs/toolkit/dist/query/react'
import {GUILD_API_ENDPOINT} from '../constants/backendUrls'
import {User, UserProfileUpdate} from './model/User'
import {axiosBaseQuery} from '../rtkQueryUtils'

export const USER_TAG = 'User'

export const userAPI = createApi({
    reducerPath: 'userAPI',
    keepUnusedDataFor: 0,
    tagTypes: [USER_TAG],
    baseQuery: retry(axiosBaseQuery({baseUrl: `${GUILD_API_ENDPOINT}/api/v1/datx/`}), {maxRetries: 0}),
    endpoints: (builder) => ({
        getUser: builder.query<User, void>({
            query: () => ({
                url: 'users/me',
                method: 'GET'
            }),
            extraOptions: {maxRetries: 3},
            transformResponse: (response: any) => response.data,
            transformErrorResponse: () => 'Error getting user profile',
            providesTags: [USER_TAG]
        }),
        updateCurrentUserInfo: builder.mutation({
            query: ({user}: {user: UserProfileUpdate}) => ({
                url: 'users/me',
                method: 'POST',
                data: user
            }),
            transformResponse: (response: any) => response.data,
            transformErrorResponse: (response: any) =>
                response?.data?.errorMessage ||
                response?.data?.message ||
                response?.data ||
                'Something went wrong during updating the user profile',
            invalidatesTags: [USER_TAG]
        })
    })
})

export const selectGetUser = userAPI.endpoints.getUser.select()

export const selectCurrentUser = createSelector(selectGetUser, (getUserResult) => getUserResult.data)
export const isGetUserError = createSelector(selectGetUser, (getUserResult) => getUserResult.isError)

export const {useGetUserQuery, useUpdateCurrentUserInfoMutation} = userAPI
